/* Testimonials.css */

.testimonials {
    padding: 50px 20px;
    text-align: center;
    background-color: #f0f8ff; /* Light background */
    animation: fadeIn 1.5s ease-in-out;
}

.testimonials h2 {
    color: #3498db; /* Blue */
    font-size: 36px;
    margin-bottom: 20px;
    position: relative;
}

.testimonials h2::after {
    content: '';
    display: block;
    width: 50px;
    height: 4px;
    background: #3498db; /* Blue */
    margin: 10px auto 0;
    border-radius: 2px;
    animation: slideIn 1s ease-in-out;
}

.testimonial-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}

.testimonial-card {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.testimonial-card p {
    font-size: 16px;
    color: #777;
    margin-bottom: 15px;
}

.testimonial-card h3 {
    font-size: 20px;
    color: #3498db; /* Blue */
}
